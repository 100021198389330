import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Loans = () => (
  <Layout>
    <SEO title="loans" />
    <h1>Loans</h1>
    <Image alt="money_tree" filename="money_tree.png"/>
  </Layout>
)

export default Loans
